import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import readXlsxFile from "read-excel-file";
import ChatSupportBtn from "./ChatSupportBtn";
import { logout } from "./Utility";
import customFetch from "./apicall/api";
import Papa from "papaparse";
const $ = window.$;


class NachFileUpload extends Component {
  constructor(props) {
    super(props);
    this.InputFile = React.createRef();
    this.state = {
      uploadFileType:'MANDATE',
      isErrOpen: false,
      api_error_message: "",
      preupldclm: [],
      csvheader: [],
      upldfiledata: [],
      isLoading:false,
      disableUpload:true,
      isUpload:false,
      uploadedFiles:[],
      invalidRowsCount:0,
      selectedPaymentGateway: "", 
      selectedPartner: "",
      selectedPayment: "",
      isGuidelinesOpen: false,
      partnerData: [],
      paymentData: [],
      selectedPaymentOption: "",
      paymentGatewayError: "",
      partnerError: "",
      uploadFileTypeError:""      

    };
  }
  
  componentDidMount() {
    this.getprecol();
    this.getpaymentData();
    this.getUploadedFilesHandler();
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.uploadFileType!== this.state.uploadFileType) {
      this.getprecol()
    }
  }


  getUploadedFilesHandler = async ()=>{
    let data = await this.getUploadedFiles()
    this.setState({uploadedFiles:data})
  }

  handleBrowse = ()=>{
    this.InputFile.current.value = ''
    this.InputFile.current.click();
  }

  ChangeXLSXHandler(upldfile) {
    readXlsxFile(upldfile).then((rows) => {
      const headers = rows[0];
      const delim = ",";
      rows.shift();
      const newArray = rows.map((row, i) => {
        const values = row.toString().split(delim);
        //console.log("headers", values[i]);
        const eachObject = headers.reduce((obj, header, i) => {
          obj[header] = values[i];
          return obj;
        }, {});
        return eachObject;
      });

      this.setState(
        {
          upldfiledata: newArray,
          csvheader: headers,
        });
    });
  }

  convertToCSV = (objects, selectedHeaders) => {
    const separator = ',';
  
    const formatRow = obj => {
      const values = selectedHeaders.map(header => obj[header]); 
      return values.map(value => `"${value}"`).join(separator);
    };
  
    const csvHeader = selectedHeaders.join(separator);
    const csvContent = `${csvHeader}\n${objects.map(formatRow).join('\n')}`;
    return csvContent;
  };
  


  ChangeCSVHandler = (upldfile) => {
    const file = upldfile;
    const reader = new FileReader();

    Papa.parse(upldfile, {
        header: true,
        complete: (result)=> {
            let data = result.data.slice(0,-1)
            let headers = result.meta.fields
            let disableUpload = false
            
            data = this.sanitizeFileData(data)
            let invalidRowsCount = this.invalidRows(data)
            this.setState({disableUpload:invalidRowsCount===0?false:true,invalidRowsCount:invalidRowsCount})
            this.setState({upldfiledata:data,csvheader:headers})
        }
    });

  }
  readuploadfile = (event) => {
    const upldfile = event.target.files[0];
    if (upldfile) {
      this.setState({
        upldfile: event.target.files[0],
      });
      if (
        upldfile["name"].split(".").pop() === "csv" ||
        upldfile["name"].split(".").pop() === "CSV"
      ) {
        this.ChangeCSVHandler(upldfile);
      }
      // if (
      //   upldfile["name"].split(".").pop() === "xlsx" ||
      //   upldfile["name"].split(".").pop() === "XLSX"
      // ) {
      //   this.ChangeXLSXHandler(upldfile);
      // }
    }
  }
  getprecol(vendor_id) {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    
    const params = {
      process:this.state.uploadFileType.toLowerCase(),
      purpose: "upload"
    };

    const queryParams = new URLSearchParams(params).toString();

    customFetch(process.env.REACT_APP_URL + "/get/vendor/fields?" + queryParams, {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          preupldclm: json
        });
      })
      .catch((error) => console.log("error", error));
  }

  getpartnerData(payment_vendor_id) {
    const { api_token } = (this.props.location && this.props.location.state) || {};
    const queryParams = new URLSearchParams();
    queryParams.append('payment_vendor_id', payment_vendor_id);
  
    customFetch(process.env.REACT_APP_URL +"/get/partner/data?" + queryParams.toString(), {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
  
        
        if (Array.isArray(data)) {
          this.setState({ partnerData: data });
        } else {
          console.error("Invalid response format. Expected an array.");
        }
      })
      .catch((error) => {
        console.error("Error in API request", error);
      });
  }
  
  getpaymentData(payment_id) {
    const { api_token } = (this.props.location && this.props.location.state) || {};
  
    customFetch(process.env.REACT_APP_URL +"/get/payment_vendor/data", {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
  
        
        if (Array.isArray(data)) {
          this.setState({ paymentData: data });
        } else {
          console.error("Invalid response format. Expected an array.");
        }
      })
      .catch((error) => {
        console.error("Error in API request", error);
      });
  }
  
  uploadpullfile = ()=>{
    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    const uid = localStorage.getItem("in_userid");
    const formdata = new FormData();

    const csvContent = this.convertToCSV(this.state.upldfiledata,this.state.csvheader);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    formdata.append("file", blob,this.state.upldfile["name"]);
    formdata.append("userid", uid);
    formdata.append("partner_vendor_payment_account_id", this.state.selectedPartner);
    formdata.append("upload_file_type", this.state.uploadFileType);



    this.setState({isLoading:true})
    customFetch(process.env.REACT_APP_URL + "/nach/file/", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        return res.json();
      })

      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isErrOpen: true,
          api_error_message: json.message,
        });
      })
      .catch((error) => console.log("error", error))
      .finally(()=>this.setState({isLoading:false}))
  }

  handlePaymentGatewayChange = (event) => {
    const payment_vendor_id = event.target.value;
    this.setState({ selectedPaymentGateway: payment_vendor_id, paymentGatewayError: "" }, () => {
      if (!payment_vendor_id) {
        this.setState({ paymentGatewayError: "Please select a payment gateway." });
      } else {
        this.getpartnerData(payment_vendor_id);
      }
    });
  };
  
  handleFileTypeChange = (event) => {
    const fileType = event.target.value;

    this.setState({ uploadFileType: fileType, uploadFileTypeError: "" });
    
    if (!fileType) {
      this.setState({ fileType: "Please select a file type." });
    }
  };

  handlePartnerChange = (event) => {
    const partnerValue = event.target.value;
    this.setState({ selectedPartner: partnerValue, partnerError: "" });
    
    if (!partnerValue) {
      this.setState({ partnerError: "Please select a partner." });
    }
  };
  
  handlePaymentChange = (event) => {
    this.getpartnerData(event.target.value)
    this.setState({ selectedPayment: event.target.value });
  };

  toggleGuidelinesPopup = () => {
    this.setState((prevState) => ({
      isGuidelinesOpen: !prevState.isGuidelinesOpen,
    }));
  };

  
  

  getUploadedFiles =async () =>{
    try{

        const  api_token  = localStorage.getItem('api_token')
        const userid = localStorage.getItem('in_userid')
        const _headers = new Headers(); 

        const params = {
          // userid: userid
        };
        const queryParams = new URLSearchParams(params).toString();
        
        const response = await fetch(process.env.REACT_APP_URL+'/nach/file/?'+queryParams, {
            method: 'GET',
            headers: {
            "api-token": api_token
            }
        });
        
        // console.log(response.status)

        if(!response.ok){
            throw "Internal Server Error"
        }
        let data = await response.json();
        // console.log(data)
        return data;
    }
    catch(e){
        console.error(e);
    }
  }

  invalidRows(data) {
    const invalidRows = data.filter(row => Object.values(row.error).some(error => error !== ''));
    return invalidRows.length;
  }

  sanitizeFileData = (rows)=>{
    for(let i=0;i<rows.length;i++){
      const error = this.validateRow(rows[i])
      rows[i] = {...rows[i],error:error}
    }
    return rows
  }

  validateRow = (row)=>{
    // all validation happends inside this row.
    // returns the errors found for each row.
    const expected_columns = [...this.state.preupldclm]
    const error = {
      'row_level_error':''
    }
    expected_columns.forEach((column)=>error[column]='')

    // Check if the row has only keys mentioned in the expected columns list, else add it as a row level error
    const rowKeys = Object.keys(row);
    const invalidKeys = rowKeys.filter(key => !expected_columns.includes(key));

    if (invalidKeys.length > 0) {
      error['row_level_error'] = 'Invalid columns found in the row';
      return error;
    }
    // Validate each column in the row
    if(this.state.uploadFileType == 'MANDATE') 
       this.validate_mandate_row(error,row)

    if(this.state.uploadFileType == 'PULL') 
       this.validate_pull_row(error,row)

    return error
  }

  validate_mandate_row = (error,row)=>{
    
    ['loan_id','umrn','account_number'].forEach((column)=>{
      if(!row[column]){
        //converts word1_word2 to WORD1 WORD2 for presentation
        error[column] = `${column.split('_').map(word => word.toUpperCase()).join(' ')} is required`;
      }
    })

    if (row['nach_type'] != 'Physical' && row['nach_type'] != 'E-NACH') {
      error['nach_type'] = 'NACH TYPE should either be Physical or E-NACH'
    }

    if (!row["ifsc"] || (/^[A-Z]{4}0[A-Z0-9]{6}$/.test(row['ifsc']) === false)){
      error['ifsc'] = 'Invalid IFSC, must contain 11 characters(Alphabets or Numbers)'
    }

  }

  validate_pull_row = (error,row)=>{
    if (!row['third_reference_number_nach']) {
      error['third_reference_number_nach'] = 'Third Reference Number is required';
    }
    
    if (!row['txn_ref_no']) {
      error['txn_ref_no'] = 'Transaction Reference Number is required';
    }

    if (!row['loan_id']) {
      error['loan_id'] = 'Loan ID is required';
    }

    if (isNaN(Number(row['amount'])) || Number(row['amount']) <= 0) {
      error['amount'] = 'Amount should be a positive number';
    }

    if (row['settlement_status'] !== 'SUCCESS' && row['settlement_status'] !== 'FAILURE') {
      error['settlement_status'] = 'Settlement Status should be either SUCCESS or FAILURE';
    }

    if (row['settlement_status'] === 'SUCCESS') {
      if (!row['settled_at']) {
        error['settled_at'] = 'Settled At is required when Settlement Status is SUCCESS';
      }
      else if (moment(row['settled_at'], "YYYY-MM-DD HH:mm:ss", true).isValid() === false) {
        error['settled_at'] = 'Invalid date format, expected format, YYYY-MM-DD HH:mm:ss'
      }
      if (!row['utr']) {
        error['utr'] = 'UTR is required when Settlement Status is SUCCESS';
      }
    }

    if (row['settlement_status'] === 'FAILURE') {
      if (!row['rejected_at']) {
        error['rejected_at'] = 'Rejected At is required when Settlement Status is FAILURE'
      }
      else if (moment(row['rejected_at'], "YYYY-MM-DD HH:mm:ss", true).isValid() === false) {
        error['rejected_at'] = 'Invalid date format, expected format, YYYY-MM-DD HH:mm:ss'
      }
      if (!row['reject_reason']) {
        error['reject_reason'] = 'Reject Reason is required when Settlement Status is FAILURE';
      }
    }

    if (moment(row['trigger_date'], "YYYY-MM-DD HH:mm:ss", true).isValid() === false) {
       error['trigger_date'] = 'Invalid date format, expected format, YYYY-MM-DD HH:mm:ss'
    }
  }

  downloadfile(filename,path) {
    const { api_token } =(this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    formdata.append("blob_url", path);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
      },
      body: formdata,
    };
    fetch(process.env.REACT_APP_URL + "/download/files/bucket", requestOptions)
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }

        if (res.api_code === 4 || res.api_code === 3 || res.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        res.blob().then((file) => {
          const url = window.URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", path.split("/")[2]);
          document.body.appendChild(link);
          link.download = filename
          link.click();
          link.parentNode.removeChild(link);
        });
      })
      .catch((error) => console.log("error", error));
  }

  handleDownloadFormat = ()=>{
      if(!this.state.preupldclm)
        return
      const json = [this.state.preupldclm.reduce((obj,clm) => {
          obj[clm]="";
          return obj;
        },{})]
      const csv_text = Papa.unparse(json,{header:true})
      // console.log(csv_text)
      const element = document.createElement("a");
      const file = new Blob([csv_text], {type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = `NACH_${this.state.uploadFileType.toUpperCase()}_ResultUpload.csv`;
      document.body.appendChild(element);
      element.click();
  }


  render() {
    const {
      upldfiledata,
      preupldclm,
      csvheader,
      api_error_message,
      isErrOpen,
      selectedPaymentOption,
      selectedPayment,
      isGuidelinesOpen,
      partnerData,
      paymentData,
      selectedPaymentGateway,
      selectedPartner,
      
    } = this.state;

    return (
      <>
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isErrOpen: false });
                  window.location.reload(true);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <div
        className={
          isGuidelinesOpen  
            ? "modal fade bd-example-modal-sm show mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="guidelines-box"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.setState({ isGuidelinesOpen: false })}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <ul>
          <li>Please upload .csv files only (any other file format will not be supported)</li>
          <li>Make sure that data in the csv file should start from cell A1</li>
          <li>Column headers used in the csv file should exactly match the table header shown in this screen</li>
          <li>All date-related fields should have yyyy-mm-dd hh:mm:ss format</li>
          <li>Settlement status to be either SUCCESS or FAILURE For Pull result files</li>
          <li>Column 'nach_type' should be either 'Physical' or 'E-NACH' For Mandate result files</li>
        </ul>
          </div>
        </div>
      </div>
        <section>
            <div className="container">
              <div className="npfu_navbar">
                <button className='button back_button' onClick={() => this.props.history.goBack()}>
                    <img
                    src={require("../assets/images/back.png")}
                    />
                </button>
                <h3>NACH FILE UPLOAD</h3>
                <button className='button' onClick={this.logout}>
                    <img
                        src={require("../assets/images/power-off.png")}
                        alt=""
                    />
                </button>
            </div>  
            {
              !this.state.isUpload && 
              <div className='npfu_action_button_block'>
                <div></div>
                <button className='btn btn-md btn-primary'  onClick={()=>{this.setState({isUpload:true})}}>Upload new file</button>
              </div>
            }
            {this.state.isUpload && (
             <div className='npfu_action_block mt-4'>
             <h4 className='utr_action_block_header'>
               <div></div>
               <div className="btn-group">
                 <button onClick={() => this.toggleGuidelinesPopup()} className='btn btn-sm btn-info rounded'>Guidelines</button>
                 <button onClick={this.handleDownloadFormat} className='btn btn-sm btn-secondary ml-2 rounded'>Download Format</button>
               </div>
             </h4>
                <div className='npfu_action_button_block'>
                <div className="col-md-3">
                    <select
                      className={`form-control ${this.state.uploadFileTypeError ? "is-invalid" : ""}`}
                      name="uploadFileType"
                      value={this.state.uploadFileType}
                      onChange={this.handleFileTypeChange}
                    >
                      <option value="">Select Upload File Type</option>
                      {[{'name':'Mandate Result','value':'MANDATE'},{'name':'Pull Result','value':'PULL'}].map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {this.state.paymentGatewayError && (
                      <div className="invalid-feedback" style={{ color: "red" }}>
                        {this.state.paymentGatewayError}
                      </div>
                    )}
                  </div>
                <div className="col-md-3">
                    <select
                      className={`form-control ${this.state.paymentGatewayError ? "is-invalid" : ""}`}
                      name="selectedPayment"
                      value={selectedPayment}
                      onChange={this.handlePaymentChange}
                    >
                      <option value="">Select Payment Gateway</option>
                      {paymentData.map((payment) => (
                        <option key={payment.payment_vendor_name} value={payment.payment_vendor_id}>
                          {payment.payment_vendor_name}
                        </option>
                      ))}
                    </select>
                    {this.state.paymentGatewayError && (
                      <div className="invalid-feedback" style={{ color: "red" }}>
                        {this.state.paymentGatewayError}
                      </div>
                    )}
                  </div>

                  <div className="col-md-3">
                    <select
                      className={`form-control ${this.state.partnerError ? "is-invalid" : ""}`}
                      name="selectedPartner"
                      value={selectedPartner}
                      onChange={this.handlePartnerChange}
                    >
                      <option value="">Select Partner</option>
                      {partnerData.map((partner) => (
                        <option key={partner.partner_code} value={partner.partner_vendor_payment_account_id}>
                          {partner.partner_code}
                        </option>
                      ))}
                    </select>
                    {this.state.partnerError && (
                      <div className="invalid-feedback" style={{ color: "red" }}>
                        {this.state.partnerError}
                      </div>
                    )}
                  </div>

                  <input hidden type="file" ref={this.InputFile} onChange={this.readuploadfile} accept=".csv"/>
                  <button className='btn btn-md btn-primary' onClick={this.handleBrowse}>Browse File</button>
                  
                  <div style={{display:'flex',gap:'5px'}}>
                  <button
                  className='btn btn-md btn-primary'
                  disabled={!this.state.selectedPayment || !this.state.selectedPartner || this.state.disableUpload}
                  onClick={this.uploadpullfile}
                >
                  {this.state.isLoading ? <i className="fa fa-circle-o-notch fa-spin"></i> : ''} Upload
                </button>

                    
                    <button className='btn btn-md btn-danger' onClick={()=>{this.setState({isUpload:false})}}>Cancel</button>
                  </div>
                </div>
                <div className='npfu_rows_count'>
                  <span>
                    {upldfiledata.length !== 0 ? 'Total records : ' + upldfiledata.length : ''}
                    <br/>
                    {upldfiledata.length !== 0 ? 'Invalid records : ' + this.state.invalidRowsCount : ''}
                  </span>
                </div>
              </div>  
            )}

            <div className="npfu_list">
              {
                !this.state.isUpload && this.state.uploadedFiles && this.state.uploadedFiles.map((file)=>{
                  let uploading_status = 'Unknown'
                  let bg_color = 'bg-secondary'
                  const status= file.uploading_status
                  if(status==null){
                    uploading_status='Pending';
                    bg_color = 'bg-primary'
                  }
                  if(status==1){
                    uploading_status='Processing';
                    bg_color = 'bg-warning'
                  }
                  if(status==2){
                    uploading_status='Completed';
                    bg_color = 'bg-success'
                  }
                  return <div
                    className="npfu_file_preview"
                    key={file.id}
                  >
                    <span className="d-flex align-items-center justify-content-between">
                      <b>
                      Uploaded by : {file.employee_name}  
                      </b>
                    </span>
                    <div className="npfu_file_summary">
                        Uploaded file : <a href="#" onClick={(e)=>{e.preventDefault();this.downloadfile(file.upload_filepath.split('/').slice(-1),file.upload_filepath)}}>{file.upload_filepath.split('/').slice(-1)}</a><br/>
                        {file.uploading_result_filepath &&  <>Validation result   : <a href="#"  onClick={(e)=>{e.preventDefault();this.downloadfile(file.filename.split('.')[0]+'_validation_result.'+file.filename.split('.').slice(-1),file.uploading_result_filepath)}}>{file.filename.split('.')[0]+'_validation_result.'+file.filename.split('.').slice(-1)}</a><br/></>}
                        {file.processing_result_filepath && <>Processing result   : <a href="#"  onClick={(e)=>{e.preventDefault();this.downloadfile(file.filename.split('.')[0]+'_processing_result.'+file.filename.split('.').slice(-1),file.processing_result_filepath)}}>{file.filename.split('.')[0]+'_processing_result.'+file.filename.split('.').slice(-1)}</a></>}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="npfu_file_created_at">
                        Uploaded at: {file.added_at}
                      </span>
                      <div
                        className={`npfu_file_status ${bg_color}`}
                      >
                        {uploading_status}
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
            

          </div>
        </section>
        {
          this.state.isUpload && <div className='utr_table_block'>
          <table className='utr_data_table'>
              <thead>
                  <tr>
                  {csvheader.length > preupldclm.length
                  ? csvheader.map((head) => <th>{head}</th>)
                  : preupldclm.map((head) => <th>{head}</th>)}
                  </tr>
              </thead>
              <tbody>
                {upldfiledata.map((row, i) => (
                  <tr key={i}
                    className={
                      row.error['row_level_error']?'bg-danger':''
                    }
                    title={row.error['row_level_error']?row.error['row_level_error']:null}>
                    {preupldclm.map((column, index) => (
                      <td
                        className={
                          row.error[column]?'bg-warning':''
                        }
                        title={row.error[column]?row.error[column]:null}
                      >
                          {
                            row[column]
                          }
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
          </table>
          </div>
        }
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(NachFileUpload);
